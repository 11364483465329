import logo from './logo.svg';
import './assets/stylesheets/App.scss';

import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom'

import Layout from "./Layout/Layout";
import Home from "./Components/pages/Home";
import Overview from "./Components/pages/Overview";



function App() {
  return (
    <div className="App">
        <Router>
            <Layout>
                <Routes>
                    <Route path={'/'} element={<Home />}/>
                </Routes>
            </Layout>
        </Router>
    </div>
  );
}

export default App;
